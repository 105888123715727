import React from 'react'
import Link from 'gatsby-link'
import Layout from '../layouts/sv'

const SecondPage = props => (
  <Layout location={props.location}>
    <div>
      <h1>Sida 2</h1>
      <Link to="/sv/">Tillbaks till start</Link>
    </div>
  </Layout>
)

export default SecondPage
